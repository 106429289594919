import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import CloseIcon from '../icons/CloseIcon';
import ChevronIcon from '../icons/ChevronIcon';

import './index.scss';

class LotDetail extends Component {

  static propTypes = {
    neighborhood: PropTypes.object,
    slug: PropTypes.string,
    lot: PropTypes.array,
    handleClose: PropTypes.func,
    useAnchors: PropTypes.bool
  }

  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: true
    };
  }

  handleCloseModal = () => {
    // Send data to parent component
    this.props.handleClose();
    this.setState({modalIsOpen: false});
  }

  findResidence = () => {
    const { neighborhood, slug, lot, useAnchors } = this.props;

    return neighborhood && neighborhood.residences.length > 0
    ?
    neighborhood.residences.map((item, i) => {
      const resPlan = lot[0].plan.slice(0,1);
      const numArr = lot[0].number.split('-');
      const number = slug === 'east-bridge' ? lot[0].number : numArr[0];
      const rendering = lot[0].rendering ? lot[0].rendering : item.renderings[0].url;
      const sqFtLow = item.sq_ft_low.substring(0,1) + ',' + item.sq_ft_low.substring(1,4);
      const sqFtHigh = item.sq_ft_high.substring(0,1) + ',' + item.sq_ft_high.substring(1,4);
      const sqFt = item.sq_ft_low === item.sq_ft_high ? sqFtHigh : sqFtLow + ' – ' + sqFtHigh;

      return item.residence === resPlan
      ?
        <div key={i} className="info-block">
          <img className="rendering" src={rendering} alt={item.renderings[0].description}/>
          <h1 className={`title ${resPlan}`}>Homesite {number}<br/>Residence {lot[0].plan}</h1>
          <p>Approx. {sqFt} Sq. Ft.</p>
          <p>{item.beds} Bedrooms</p>
          <p>{item.baths} Bathrooms</p>
          <p>{item.garage}-Car Garage</p>
          <a href={`https://heritagegrovefillmore.com/neighborhoods/${slug}/?residence=${item.residence}`} className={`floor-plan-link ${resPlan}`} target="_parent">Floor Plan {ChevronIcon}</a>
          {/* {
            useAnchors
            ?
            <a href={`/neighborhoods/${slug}/?residence=${item.residence}`} className={`floor-plan-link ${resPlan}`}>Floor Plans {ChevronIcon}</a>
            :
            <NavLink to={`/neighborhoods/${slug}/residence-${item.residence}`} className={`floor-plan-link ${resPlan}`}>Floor Plans {ChevronIcon}</NavLink>
          } */}
        </div>
      :
      null
    })
    :
    null
  }

  render() {
    const residence = this.findResidence();
    const openClass = this.state.modalIsOpen ? 'open' : null;

    return (
      <div id="lot-detail">
        <div className="overlay" onClick={this.handleCloseModal}></div>
        <div className={`content ${openClass}`}>
          <button className="close" onClick={this.handleCloseModal}>{CloseIcon}</button>
          {residence}
        </div>
      </div>
    )
  }

}

export default LotDetail;
