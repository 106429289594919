import React, { Component } from "react"
import PropTypes from "prop-types"

import LotDetail from "../../components/LotDetail"
import Lot from "../../components/LotDetail/Lot"

import Bubbles02 from "../../img/bubbles-02.svg"
import AerialSitemap from "../../img/aerial-site-map-3-25-24.jpg"
import HGSitemap from "../../img/heritage-grove-site-map-master-v2-4x.jpg"
import OrchardsSitemap from "../../img/the-orchards-sitemap-v7-4x.png"

import "./index.scss"

class SiteMap extends Component {
  static propTypes = {
    anchors: PropTypes.bool,
  }

  constructor(props) {
    super(props)

    this.state = {
      hash: "the-orchards",
      neighborhoods: [],
      lots: [],
      currLot: [],
      currLotNumber: "",
      currLotStatus: "",
      zoomed: false,
      zoomScale: "1",
      zoomX: "0",
      zoomY: "0",
      showDetail: false,
    }
  }

  componentDidMount() {
    let neighborhoods = sessionStorage.getItem("neighborhoods")

    if (neighborhoods) {
      neighborhoods = JSON.parse(neighborhoods)
      this.setState({ neighborhoods })
      this.setLotData(neighborhoods)
    } else {
      fetch("https://heritagegrovefillmore.com/?rest_route=/acf/v3/neighborhoods")
        .then(response => {
          return response.json()
        })
        .then(data => {
          this.setState({ neighborhoods: data })
          this.setLotData(data)
          sessionStorage.setItem("neighborhoods", JSON.stringify(data))
        })
    }
  }

  setHash = str => {
    const { neighborhoods } = this.state
    const url = window.location.origin + window.location.pathname
    window.location = url + "#" + str
    this.setState({ hash: str }, function () {
      this.setLotData(neighborhoods)
    })
  }

  setLotData = data => {
    const { hash } = this.state
    let arr = []
    console.log("hash: ", hash)

    data.map(item => {
      const slug = item.acf.name.toLowerCase().split(" ").join("-")
      return (item.acf.lots && hash === "iron-horse" && slug !== "the-orchards") ||
        (item.acf.lots && hash === slug)
        ? item.acf.lots.map(l => {
            let lot = {}
            lot.neighborhood = item.acf
            lot.hoodSlug = slug
            lot.number = slug === "east-bridge" ? l.number : l.number + "-IH"
            lot.status = l.status
            lot.plan = l.plan
            lot.statusOffsetX = l.statusOffsetX
            lot.statusOffsetY = l.statusOffsetY
            lot.path = l.path
            lot.rendering = l.rendering
            return arr.push(lot)
          })
        : null
    })
    this.setState({ lots: arr })
    // return arr;
  }

  renderLotDetail = () => {
    const { currLot } = this.state
    const { anchors } = this.props

    return currLot.length > 0 ? (
      <LotDetail
        key={currLot[0].number}
        neighborhood={currLot[0].neighborhood}
        slug={currLot[0].hoodSlug}
        lot={currLot}
        handleClose={this.handleShowDetail}
        useAnchors={anchors}
      />
    ) : null
  }

  renderHoods = () => {
    const { hash, neighborhoods } = this.state

    return neighborhoods.map((item, i) => {
      const hood = item.acf
      const slug = hood.name.toLowerCase().split(" ").join("-")

      return hash === slug ? (
        <div key={i} className={`hood ${slug}`}>
          <img className={`hood-logo ${slug}`} src={hood.hood_logo.url} alt={hood.name} />
          <ul className="hood-details">
            <li>{hood.hood_sq_ft} Sq. Ft.</li>
            <li>{hood.hood_beds} Bedrooms</li>
            <li>{hood.hood_baths} Bathrooms</li>
            <li>{hood.hood_garages}</li>
          </ul>
        </div>
      ) : null
    })
  }

  setCurrLot = (e, lot, lotNumber, lotStatus) => {
    e.stopPropagation()
    const activeLot = this.setActiveLot(lotNumber)
    this.setState({
      currLot: activeLot,
      currLotNumber: lotNumber,
      currLotStatus: lotStatus,
      showDetail: true,
    })
  }

  handleShowDetail = () => {
    const { showDetail } = this.state
    this.setState({
      showDetail: !showDetail,
      currLotNumber: "",
    })
  }

  setLots = () => {
    const { lots } = this.state

    return lots && lots.length > 0
      ? lots.map((lot, i) => {
          let center = this.setCircle(lot.path)
          return lot.path ? (
            <Lot
              key={i}
              lot={lot}
              center={center}
              activeLot={this.state.currLotNumber}
              handleClick={this.setCurrLot}
            />
          ) : null
        })
      : null
  }

  setCircle = coordString => {
    // Get the center coordinates of the polygon
    var coords = coordString.split(" ")
    var posXmax, posYmax, posXmin, posYmin
    for (var a = 0, l = coords.length; a < l; a += 2) {
      if (a === 0) {
        posXmin = Number(coords[a])
        posXmax = Number(coords[a])
        posYmin = Number(coords[a + 1])
        posYmax = Number(coords[a + 1])
      } else {
        if (Number(coords[a]) < posXmin) {
          posXmin = Number(coords[a])
        }
        if (Number(coords[a + 1]) < posYmin) {
          posYmin = Number(coords[a + 1])
        }
        if (Number(coords[a]) > posXmax) {
          posXmax = Number(coords[a])
        }
        if (Number(coords[a + 1]) > posYmax) {
          posYmax = Number(coords[a + 1])
        }
      }
    }
    // Set position to center
    return { x: (posXmax + posXmin) / 2, y: (posYmax + posYmin) / 2 }
  }

  setActiveLot = lotNumber => {
    const { lots } = this.state

    return lots && lots.length > 0
      ? lots.filter(lot => {
          return lotNumber === lot.number
        })
      : null
  }

  handleZoom = e => {
    const { zoomed } = this.state
    const X = e.clientX - 398
    const Y = e.clientY - 160
    const vbWidth = 1354
    const vbHeight = 650
    const xVal =
      X > vbWidth / 4 && X < (vbWidth / 4) * 3 ? "0" : X > (vbWidth / 4) * 3 ? "-720" : "720"
    const yVal =
      Y > vbHeight / 4 && Y < (vbHeight / 4) * 3 ? "0" : Y > (vbHeight / 4) * 3 ? "-325" : "325"

    zoomed
      ? this.setState({
          zoomScale: "1",
          zoomX: "0",
          zoomY: "0",
          zoomed: false,
        })
      : this.setState({
          zoomScale: "1.75",
          zoomX: xVal,
          zoomY: yVal,
          zoomed: true,
        })
  }

  renderSitemap = () => {
    const { hash, zoomed, zoomScale, zoomX, zoomY } = this.state
    const hoods = this.renderHoods()
    const zoomClass = zoomed ? "zoomed" : ""
    const showLots = this.setLots()
    const style = {
      transform: "matrix(" + zoomScale + ",0,0," + zoomScale + "," + zoomX + "," + zoomY + ")",
    }

    return hash === "aerial" ? (
      <div id="site-map-detail" className="main-container">
        <img id="site-map-img" className="aerial" src={AerialSitemap} alt="Aerial Site Map" />
      </div>
    ) : hash === "the-orchards" ? (
      <div id="site-map-detail" className="main-container orchards">
        <div id="status-key">
          <ul>
            <li className="available">Available</li>
            <li className="reserved">Reserved</li>
            <li className="sold">Sold</li>
            <li className="quick-move-in">Quick Move-In</li>
          </ul>
        </div>
        <div
          id="site-map-detail-wrap"
          className={zoomClass}
          style={style}
          onClick={e => this.handleZoom(e)}
        >
          {/* <div id="hoods">{hoods}</div> */}
          <img id="site-map-img" src={OrchardsSitemap} alt="Orchards Site Map" />
          <svg
            id="lots"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1520 701.75"
            width="1520"
            height="702"
          >
            {showLots}
          </svg>
        </div>
      </div>
    ) : (
      <div id="site-map-detail" className="main-container">
        <div id="status-key">
          <ul>
            <li className="available">Available</li>
            <li className="reserved">Reserved</li>
            <li className="sold">Sold</li>
            <li className="quick-move-in">Quick Move-In</li>
          </ul>
        </div>
        <div
          id="site-map-detail-wrap"
          className={zoomClass}
          style={style}
          onClick={e => this.handleZoom(e)}
        >
          {/* <div id="hoods">{hoods}</div> */}
          <img id="site-map-img" src={HGSitemap} alt="Heritage Grove Site Map" />
          <svg
            id="lots"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1760.16 934.3376"
            width="1760"
            height="934"
          >
            {showLots}
          </svg>
        </div>
      </div>
    )
  }

  render() {
    const { hash, showDetail } = this.state
    const showSitemap = this.renderSitemap()
    const showLotDetail = showDetail ? this.renderLotDetail() : null

    return (
      <div id="site-map" className="main-container">
        <div id="site-map-wrap">
          <ul className="site-map-nav">
            <li
              className={hash === "the-orchards" ? "active" : null}
              onClick={() => this.setHash("the-orchards")}
            >
              <span>The Orchards</span>
            </li>
            <li
              className={hash === "iron-horse" ? "active" : null}
              onClick={() => this.setHash("iron-horse")}
            >
              <span>Iron Horse</span>
            </li>
            <li
              className={hash === "aerial" ? "active" : null}
              onClick={() => this.setHash("aerial")}
            >
              <span>Aerial</span>
            </li>
          </ul>
          {showSitemap}
          {showLotDetail}
        </div>
      </div>
    )
  }
}

export default SiteMap
