import { BrowserRouter as Router } from 'react-router-dom';
import SiteMap from '../SiteMap';
// import './index.css';

function App() {
  return (
    <Router>
      <div className="app">
        <SiteMap anchors />
      </div>
    </Router>
  );
}

export default App;
