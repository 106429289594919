import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Lot extends Component {

  static propTypes = {
    lot: PropTypes.object,
    handleClick: PropTypes.func,
    center: PropTypes.object,
    activeLot: PropTypes.string
  }

  handleLotClick = (e, lotSlug, lotNumber, lotStatus) => {
    // Send data to parent component
    this.props.handleClick(e, lotSlug, lotNumber, lotStatus);
  }

  render() {
    const {
      lot,
      center,
      activeLot
    } = this.props;
    const activeClass = lot.number === activeLot ? 'active' : null;
    const statusOffsetX = parseInt(lot.statusOffsetX, 10);
    const statusOffsetY = parseInt(lot.statusOffsetY, 10);
    const rectangleX = parseInt(lot.rectangleX, 10);
    const rectangleY = parseInt(lot.rectangleY, 10);
    const textX = lot.isRectangle ? rectangleX + statusOffsetX : center.x + statusOffsetX;
    const textY = lot.isRectangle ? rectangleY + statusOffsetY : center.y + statusOffsetY;

    return (
      <g data-lot={lot.number} data-plan={lot.plan} className={lot.status === 'disabled' ? 'disabled' : null} onClick={(e) => this.handleLotClick(e, lot.hoodSlug, lot.number, lot.status)}>
        {
          lot.isRectangle
          ?
          <rect x={lot.rectangleX} y={lot.rectangleY} width={lot.rectangleWidth} height={lot.rectangleHeight} transform={lot.rectangleTransform} />
          :
          <polygon points={lot.path} className={`${activeClass} ${lot.status}`} />
        }
        <circle className={lot.status} cx={center.x} cy={center.y} r="7" />
        <text className={lot.status} x={textX} y={textY} textAnchor="middle" alignmentBaseline="middle">
          {lot.status === 'available' ? 'A' : lot.status === 'sold' ? 'S' : lot.status === 'model' ? 'M' : lot.status === 'reserved' ? 'R' : ''}
        </text>

      </g>
    )
  }
}

export default Lot;
