import React from 'react';

const closeIcon = 
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
    <title>close</title>
    <rect x="-6.82" y="17.07" width="48.63" height="0.86" transform="translate(17.5 -7.25) rotate(45)"/>
    <rect x="-6.82" y="17.07" width="48.63" height="0.86" transform="translate(42.25 17.5) rotate(135)"/>
  </svg>

export default closeIcon;
